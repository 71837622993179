export const tags = ['Blog']

// TODO - Is article meta going to pull article data as well?
export const siteName = "White Owl Education"

export const blogPost = {
    id: "2021-03-29-00",
    created_at: "2021-03-29T00:00:00.000-00:00",
    updated_at: "2021-03-29T00:00:00.000-00:00",
    page: {
        url: "https://www.whiteowleducation.com/4-ways-to-effectively-debug-data-pipelines-in-apache-beam/"
    },
    author: {
        type: "Person",
        name: "White Owl Education",
        author_image_url: "https://whiteowleducation.com/images/authors/white-owl-education/white-owl-education-author-face.jpg",
        pathname: "images/authors/white-owl-education/white-owl-education-author-face.jpg",
        author_image_description: "Picture of Ralph Brooks, CEO of White Owl Education"
    },
    title: "4 Ways to Effectively Debug Data Pipelines in Apache Beam",
    publisher: {
        name: siteName,
        url: "https://whiteowleducation.com/images/theme/header/logo/wo-logo-transparent.png"
    },
    description: "Learn how to use labels and unit tests to make your data feeds more robust! ",
    image: {
        url: "https://whiteowleducation.com/images/blog/2021/03/29/card-main-image.png",
        description: "Illustration of a data pipeline."
    },
    internal_featured_image: {
        pathname: "images/blog/2021/03/29/data-pipeline-green.png"
    },
    keywords: tags[0],
    featured: "no",
    read_time_minutes : "5",
    visibility: "public"
}